.ct-all-staking-pools {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ct-all-staking-pools > div {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-bottom: 20px;
  padding: 20px;
  background: white;
  width: 90%;
  position: relative;
}

.ct-all-staking-pools > div .ct-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.ct-all-staking-pools > div .ct-header > .ct-tokens-list {
  display: flex;
  margin-bottom: 10px;
}

.ct-all-staking-pools > div .ct-header > .ct-tokens-list > span {
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  width: 50px;
  height: 50px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -5px;
}

.ct-all-staking-pools > div .ct-header .ct-old-pool {
  background: rgba(147, 20, 20, 0.771);
  position: absolute;
  top: -20px;
  left: -20px;
  width: calc(100% + 40px);
  height: calc(100% + 20px);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

.ct-all-staking-pools > div .ct-header .ct-distribution-pool {
  background: rgba(93, 175, 50, 0.771);
  position: absolute;
  top: -20px;
  left: -20px;
  width: calc(100% + 40px);
  height: calc(100% + 20px);
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: black;
}

.ct-all-staking-pools > div .ct-header .ct-old-pool > h4,
.ct-all-staking-pools > div .ct-header .ct-distribution-pool > h4 {
  font-size: 18px;
}

.ct-all-staking-pools > div .ct-header .ct-old-pool > p,
.ct-all-staking-pools > div .ct-header .ct-distribution-pool > p {
  text-transform: uppercase;
  font-size: 10px;
}

.ct-all-staking-pools > div .ct-header .ct-old-pool > p > strong {
  text-decoration: underline;
  margin-top: 5px;
  display: block;
}

.ct-all-staking-pools > div .ct-header > .ct-tokens-list > span > img {
  width: 100%;
}

.ct-all-staking-pools > div > .ct-configuration {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  border-top: 1px solid grey;
}

.ct-all-staking-pools > div > .ct-configuration > div {
  max-width: 300px;
  width: 100%;
  padding: 10px;
  border-right: 1px solid grey;
  margin-top: 15px;
}

.ct-all-staking-pools > div > .ct-configuration > div:last-child {
  border: 0;
}

.ct-all-staking-pools > div > .ct-configuration > div > h5 {
  font-size: 16px;
}

.ct-all-staking-pools > div > .ct-configuration > div > strong {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.ct-all-staking-pools > div > .ct-configuration > div > small {
  font-size: 12px;
}

.ct-all-staking-pools > div > .ct-configuration > div > strong > a {
  font-size: 12px;
}

.ct-all-staking-pools > div > .ct-configuration > div .ct-token-selector {
  margin-top: 7px;
  margin-bottom: 7px;
}

.ct-all-staking-pools > div > .ct-configuration > div .ct-collection-selector {
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 3px;
  margin-top: 7px;
  height: 38px;
  margin-bottom: 7px;
  width: 100%;
  padding: 0 7px;
  font-size: 12px;
}

.ct-all-staking-pools > div > .ct-configuration > div.ct-collection {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.ct-nft-staking-pool .ct-active-collections-list,
.ct-nft-staking-pool .ct-active-tokens-list {
  display: flex;
  margin-top: 5px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.ct-nft-staking-pool .ct-active-tokens-list > div,
.ct-nft-staking-pool .ct-active-collections-list > div {
  background: rgb(214, 25, 25);
  padding: 7px;
  width: auto;
  color: white;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  text-transform: uppercase;
  margin: 2px 5px;
}

.ct-nft-staking-pool .ct-active-tokens-list > div > span,
.ct-nft-staking-pool .ct-active-collections-list > div > span {
  margin-left: 5px;
}

.ct-nft-staking-pool .ct-staking-info {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;
}

.ct-nft-staking-pool .ct-staking-info > div {
  font-size: 10px;
  display: flex;
  width: 90%;
  flex-direction: column;
  margin-bottom: 15px;
}

.ct-nft-staking-pool .ct-staking-info > div.ct-staker > small {
  text-transform: uppercase;
  font-size: 10px;
  margin-top: 7px;
}

.ct-nft-staking-pool .ct-staking-info > div.ct-staker > p {
  margin-top: 4px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 9px;
}

.ct-nft-staking-pool .ct-staking-info > div.ct-staker strong {
  font-variant: small-caps;
}

.ct-nft-staking-pool .ct-staking-info > div.ct-actions {
  align-items: flex-start;
  text-transform: uppercase;
}

.ct-nft-staking-pool .ct-rewards {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.ct-nft-staking-pool .ct-rewards > .ct-update {
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
  flex-direction: column;
}

.ct-nft-staking-pool .ct-rewards > .ct-update > small {
  font-size: 12px;
}

.ct-nft-staking-pool .ct-rewards > .ct-update > span {
  font-size: 12px;
  cursor: pointer;
  margin-left: 5px;
}

@media screen and (min-width: 400px) {
  .ct-all-staking-pools > div .ct-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .ct-nft-staking-pool .ct-rewards {
    flex-direction: column;
    align-items: flex-end;
  }
}

@media screen and (min-width: 700px) {
  .ct-nft-staking-pool .ct-staking-info {
    justify-content: space-between;
    flex-direction: row;
  }

  .ct-nft-staking-pool .ct-staking-info > div {
    width: 100%;
  }

  .ct-nft-staking-pool .ct-staking-info > div.ct-actions {
    align-items: flex-end;
  }
}

.ct-nft-staking-pool .ct-title {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.ct-nft-staking-pool .ct-title > span {
  cursor: pointer;
}

.ct-nft-staking-pool .ct-token-selector,
.ct-nft-staking-pool .input {
  font-size: 12px;
  font-family: "Roboto Condensed", sans-serif;
}

.ct-nft-staking-pool .ct-distribution > small {
  display: block;
  text-align: center;
  margin-bottom: 10px;
}

.ct-nft-staking-pool .ct-distribution > .ct-property {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.ct-nft-staking-pool .ct-distribution > .ct-property input {
  height: 37px;
  border: 1px solid hsl(0, 0%, 80%);
  padding: 0 7px;
  border-radius: 3px;
  font-size: 12px;
}

.ct-nft-staking-pool .ct-distribution > .ct-property .ct-save-input {
  display: flex;
  justify-content: space-between;
  height: 37px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.ct-nft-staking-pool .ct-distribution > .ct-property .ct-save-input > input {
  flex: 0 0 80%;
  max-width: 80%;
}

.ct-nft-staking-pool .ct-distribution > .ct-property .ct-save-input > button {
  flex: 0 0 20%;
  height: 100%;
  cursor: pointer;
}

.ct-nft-staking-pool .ct-distribution > .ct-property > small {
  font-size: 10px;
}

.ct-nft-staking-pool .ct-name > h4 {
  font-size: 14px;
  text-transform: uppercase;
  font-variant: none;
}

.ct-nft-staking-pool .ct-nfts-panel {
  border-top: 1px solid grey;
  margin-top: 20px;
  padding: 20px 0;
}

.ct-nft-staking-pool .ct-nfts-panel > p {
  text-align: center;
  font-weight: bold;
}

.ct-nft-staking-pool .ct-nfts-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.ct-nft-staking-pool .ct-nfts-list > div {
  display: flex;
  flex-direction: row;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.ct-nft-staking-pool .ct-actions > small {
  cursor: pointer;
}

.ct-nft-staking-pool .ct-actions > p {
  font-size: 10px;
}

.ct-nft-staking-pool .ct-nfts-list > div > div {
  width: 150px;
  height: 150px;
  position: relative;
  margin: 6px;
}

.ct-nft-staking-pool .ct-nfts-list > div > div > div {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(223, 71, 71, 0.674);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: white;
}

.ct-nft-staking-pool .ct-nfts-list > div > div:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ct-nft-staking-pool .ct-nfts-list > div > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.ct-nft-staking-pool .ct-nfts-list > div > div > h4 {
  padding: 10px;
  font-size: 12px;
  text-align: center;
}

.ct-nft-staking-pool .ct-nfts-list > div > div.ct-non-approved {
  background: rgba(255, 0, 0, 0.282);
}

.ct-nft-staking-pool .ct-nfts-list > div > div.ct-staked {
  background: rgba(19, 182, 19, 0.439);
  cursor: not-allowed;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.3), 0 7px 8px rgba(21, 243, 21, 0.439);
}

.ct-nft-staking-pool .ct-nfts-list > div > div > span {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  background: white;
  padding: 5px;
}

.ct-statistics-panel {
  padding: 10px;
}
