.ct-top-nav {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 60px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  z-index: 9999;
  background: white;
}

.ct-top-nav > .ct-max-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.ct-top-nav > .ct-max-container > .ct-title {
  padding: 0 20px 0 20px;
}

.ct-top-nav > .ct-max-container > .ct-title > h1 {
  font-size: 16px;
  font-weight: lighter;
  display: flex;
  align-items: center;
}

.ct-top-nav > .ct-max-container > .ct-title > h1 > span {
  margin-right: 10px;
}

.ct-top-nav > .ct-max-container > .ct-title > h1 > img {
  width: 60px;
  padding-right: 15px;
}

.ct-top-nav > .ct-max-container > .ct-links {
  display: flex;
  padding-right: 20px;
}

.ct-top-nav > .ct-max-container > .ct-links > .ct-data {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.ct-top-nav > .ct-max-container > .ct-links .ct-balance {
  font-size: 9px;
  font-weight: lighter;
  display: flex;
  align-items: center;
  text-align: right;
}

.ct-top-nav > .ct-max-container > .ct-links .ct-balance > a {
  font-size: 11px;
  margin-right: 7px;
  cursor: pointer;
  color: black;
}

.ct-top-nav > .ct-max-container > .ct-links .ct-address {
  text-overflow: ellipsis;
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  direction: rtl;
  font-size: 9px;
  font-weight: lighter;
  text-align: right;
}

.ct-top-nav > .ct-max-container > .ct-links > button {
  background: white;
  border: 0;
  border-bottom: 1px solid black;
  cursor: pointer;
  border-radius: 0;
  color: black;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: lighter;
}

.ct-top-nav > .ct-max-container > .ct-links .ct-actions {
  margin-left: 5px;
  display: flex;
  align-items: center;
}

.ct-top-nav > .ct-max-container > .ct-links .ct-actions > span,
.ct-top-nav > .ct-max-container > .ct-links .ct-actions > a {
  cursor: pointer;
  margin-left: 5px;
  font-size: 16px;
}

@media screen and (min-width: 500px) {
  .ct-top-nav > .ct-max-container > .ct-links .ct-balance {
    font-size: 12px;
  }

  .ct-top-nav > .ct-max-container > .ct-links .ct-balance > a {
    font-size: 12px;
  }

  .ct-top-nav > .ct-max-container > .ct-links .ct-address {
    font-size: 12px;
  }
}

@media screen and (min-width: 1600px) {
  .ct-top-nav > .ct-max-container > .ct-links .ct-actions .ct-left-toggler {
    display: none;
  }
}
