.ct-send-staking-rewards {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 7px;
  flex-wrap: wrap;
  justify-content: center;
}

.ct-send-staking-rewards > input {
  flex: 0 0 48.5%;
}

.ct-send-staking-rewards > button {
  flex: 0 0 48.5%;
}

.ct-send-staking-rewards > small {
  flex: 0 0 100%;
  text-align: center;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
}
