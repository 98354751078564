.ct-lp-staking > .ct-staking {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  width: 90%;
  margin: 0 auto 50px auto;
}

.ct-lp-staking > .ct-staking > .ct-header {
  justify-content: center;
  margin-bottom: 7px;
}

.ct-lp-staking > .ct-staking > .ct-header > .ct-icons {
  margin-right: 10px;
  display: flex;
  justify-content: center;
}

.ct-lp-staking > .ct-staking > .ct-header > .ct-icons > img {
  width: 40px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.ct-lp-staking > .ct-staking > .ct-header > .ct-icons > img:nth-child(2) {
  margin-left: -8px;
}

.ct-lp-staking > .ct-staking > .ct-header > .ct-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ct-lp-staking > .ct-staking > .ct-header > .ct-title > h4 {
  font-weight: bold;
  font-size: 18px;
}

.ct-lp-staking > .ct-staking > .ct-header > .ct-title > p {
  font-size: 9px;
  text-transform: uppercase;
}

.ct-lp-staking > .ct-staking > .ct-stake {
  display: flex;
  flex-direction: column;
  z-index: 99;
}

.ct-lp-staking > .ct-staking > .ct-stake > .ct-user-info {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  font-size: 12px;
  align-items: center;
  margin-bottom: 10px;
}

.ct-lp-staking > .ct-staking > .ct-stake .ct-stake-lps-container {
  width: 100%;
  height: calc(100vh - 60px);
  background: rgba(0, 0, 0, 0.709);
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-lp-staking
  > .ct-staking
  > .ct-stake
  > .ct-stake-lps-container
  .ct-stake-lps {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  background-color: white;
  width: 90%;
  max-width: 350px;
  padding: 30px 20px;
  position: relative;
  box-shadow: 0 3px 6px rgba(255, 255, 255, 0.16),
    0 3px 6px rgba(255, 255, 255, 0.23);
}

.ct-lp-staking
  > .ct-staking
  > .ct-stake
  > .ct-stake-lps-container
  .ct-stake-lps
  .ct-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.ct-lp-staking
  > .ct-staking
  > .ct-stake
  > .ct-stake-lps-container
  .ct-stake-lps
  > h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.ct-lp-staking
  > .ct-staking
  > .ct-stake
  > .ct-stake-lps-container
  .ct-stake-lps
  > input {
  width: 90%;
  margin-bottom: 5px;
  margin-top: 10px;
}

.ct-lp-staking
  > .ct-staking
  > .ct-stake
  > .ct-stake-lps-container
  .ct-stake-lps
  > button {
  margin-bottom: 5px;
  width: 90%;
}

.ct-lp-staking
  > .ct-staking
  > .ct-stake
  > .ct-stake-lps-container
  .ct-stake-lps
  > div {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ct-lp-staking
  > .ct-staking
  > .ct-stake
  > .ct-stake-lps-container
  .ct-stake-lps
  > div
  button {
  width: 100%;
  margin-top: 7px;
}

.ct-lp-staking
  > .ct-staking
  > .ct-stake
  > .ct-stake-lps-container
  .ct-stake-lps
  > div
  small {
  font-size: 10px;
}

.ct-lp-staking
  > .ct-staking
  > .ct-stake
  > .ct-stake-lps-container
  .ct-stake-lps
  > small {
  font-size: 10px;
}

.ct-lp-staking > .ct-staking > .ct-rewards {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.ct-lp-staking > .ct-staking > .ct-rewards > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  height: 80px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-bottom: 7px;
  padding: 10px;
}

.ct-lp-staking > .ct-staking > .ct-rewards > div > img {
  position: absolute;
  bottom: -30px;
  width: 100px;
  opacity: 0.5;
  z-index: 1;
}

.ct-lp-staking > .ct-staking > .ct-rewards > div > img:nth-child(1) {
  right: 5%;
}

.ct-lp-staking > .ct-staking > .ct-rewards > div > img:nth-child(2) {
  right: 15%;
}

.ct-lp-staking > .ct-staking > .ct-rewards > div > img:nth-child(3) {
  right: 25%;
}

.ct-lp-staking > .ct-staking > .ct-rewards > div > img:nth-child(4) {
  right: 35%;
}

.ct-lp-staking > .ct-staking > .ct-rewards > div > img:nth-child(5) {
  right: 45%;
}

.ct-lp-staking > .ct-staking > .ct-rewards > div > div {
  text-transform: uppercase;
  font-size: 11px;
}

.ct-lp-staking > .ct-staking > .ct-rewards > div > div > h4 {
  font-size: 20px;
}

.ct-lp-staking > .ct-staking > .ct-rewards > div > div > button.ct-main-button {
  width: 50px;
  height: 50px;
  background: rgba(161, 53, 53, 0.489);
  margin-top: 10px;
  z-index: 50;
  position: relative;
}

.ct-lp-staking
  > .ct-staking
  > .ct-rewards
  > div
  > div
  > button.ct-main-button.ct-active {
  background: rgba(57, 161, 53, 0.489);
}

.ct-lp-staking
  > .ct-staking
  > .ct-rewards
  > div
  > div
  > button.ct-main-button
  > img {
  width: 50px;
  height: 50px;
}

.ct-lp-staking > .ct-staking > .ct-icons {
  display: flex;
  flex-direction: column;
  font-size: 9px;
  text-transform: uppercase;
  margin-top: 15px;
}

.ct-lp-staking > .ct-staking > .ct-icons > div {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  align-items: center;
  margin-top: -10px;
}

.ct-lp-staking > .ct-staking > .ct-icons > div > img {
  width: 25px;
}

@media screen and (min-width: 440px) {
  .ct-lp-staking > .ct-staking > .ct-header {
    justify-content: flex-start;
    display: flex;
  }

  .ct-lp-staking > .ct-staking > .ct-header > .ct-icons {
    justify-content: flex-start;
  }

  .ct-lp-staking > .ct-staking > .ct-header > .ct-title {
    align-items: flex-start;
  }

  .ct-lp-staking > .ct-staking > .ct-header > .ct-title > h4 {
    font-weight: bold;
    font-size: 20px;
  }

  .ct-lp-staking > .ct-staking > .ct-header > .ct-title > p {
    font-size: 10px;
    text-transform: uppercase;
  }

  .ct-lp-staking > .ct-staking > .ct-stake > .ct-user-info {
    align-items: flex-start;
  }

  .ct-lp-staking > .ct-staking > .ct-stake > button {
    width: 100%;
  }

  .ct-lp-staking > .ct-staking > .ct-rewards > div > img:nth-child(1) {
    right: 3%;
  }

  .ct-lp-staking > .ct-staking > .ct-rewards > div > img:nth-child(2) {
    right: 7%;
  }

  .ct-lp-staking > .ct-staking > .ct-rewards > div > img:nth-child(3) {
    right: 11%;
  }

  .ct-lp-staking > .ct-staking > .ct-rewards > div > img:nth-child(4) {
    right: 15%;
  }

  .ct-lp-staking > .ct-staking > .ct-rewards > div > img:nth-child(5) {
    right: 19%;
  }
}

.ct-staking-announce {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 15px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  width: 90%;
  margin: 0 auto 50px auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.ct-staking-announce > div {
  display: flex;
  flex-direction: column;
}

.ct-staking-announce > div > h4 {
  font-size: 35px;
}

.ct-staking-announce img {
  width: 200px;
}

.ct-pool-creation > div {
  background: white;
  width: 90%;
  margin: 0 auto 50px auto;
  padding: 15px;
}

.ct-pool-creation > div > h4 {
  font-size: 25px;
}

.ct-pool-creation > div > p {
  font-size: 12px;
}

.ct-pool-creation > div > button {
  max-width: 200px;
  margin-top: 15px;
}
