@import url("https://fonts.googleapis.com/css2?family=Bangers&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto+Slab:wght@300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;1,100;1,300;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&family=Permanent+Marker&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import "./styles/collections/manga-punks.css";
@import "./styles/lpStaking.css";

html {
  scroll-behavior: smooth;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto Condensed", sans-serif;
}

body {
  max-width: 100vw;
  max-height: 100vh;
}

.ct-app-container {
  width: 100%;
  position: relative;
  margin-top: 60px;
}

.ct-max-container {
  max-width: 1200px;
  margin: 0 auto;
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Buttons                               */
/* -------------------------------------------------------------------------- */

.ct-main-button {
  transition: all 0.2s ease;
  background: black;
  border: 0;
  color: white;
  padding: 0 15px;
  font-weight: bold;
  cursor: pointer;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98%;
  height: 30px;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 10px;
}

.ct-main-button:disabled:hover {
  background: #ef9a9a;
}

.ct-main-button.ct-active {
  background: #7e7e7e;
}

.ct-main-button:hover {
  background: #7e7e7e;
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Margins                               */
/* -------------------------------------------------------------------------- */

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: lowercase;
  font-variant: small-caps;
}

.ct-mb-1 {
  margin-bottom: 1px;
}

.ct-mb-2 {
  margin-bottom: 2px;
}

.ct-mb-20 {
  margin-bottom: 20px !important;
}

.ct-mb-5 {
  margin-bottom: 5px;
}

.ct-pt-30 {
  padding-top: 30px;
}

.ct-pt-20 {
  padding-top: 20px;
}

.ct-pb-10 {
  padding-bottom: 10px;
}

.ct-mt-5 {
  margin-top: 5px;
}

.ct-mt-10 {
  margin-top: 10px;
}

.ct-mt-20 {
  margin-top: 20px;
}

.ct-mr-5 {
  margin-right: 5px !important;
}

.ct-mr-10 {
  margin-right: 10px !important;
}

.ct-ml-5 {
  margin-left: 5px !important;
}

a {
  font-weight: bold;
  color: black;
}

.ct-title {
  display: flex;
  flex-direction: column;
}

.ct-title > span:nth-child(2) {
  font-size: 10px;
  font-weight: bold;
}

input {
  text-transform: uppercase;
  font-size: 10px;
}

input,
input:hover,
input:active,
input:focus {
  outline: none;
}

textarea,
textarea:focus,
textarea:active {
  border: 0;
  border-bottom: 1px solid black;
  outline: none;
  padding: 10px;
}

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Comming                               */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                               ANCHOR Spinners                               */
/* -------------------------------------------------------------------------- */

.spinner {
  width: 40px;
  height: 40px;
  background-color: #333;
  margin: 100px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

.Toastify {
  z-index: 999999999999 !important;
}

.Toastify__toast {
  z-index: 999999999999 !important;
}

/* -------------------------------------------------------------------------- */
/*                            ANCHOR Universal Minter                         */
/* -------------------------------------------------------------------------- */

button {
  background-color: black;
  color: white;
  border: 0;
  border-radius: 3px;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  height: 25px;
}

button:disabled {
  background: rgb(211, 89, 89);
}

.ct-page-header {
  position: relative;
  margin-bottom: 20px;
}

.ct-page-header > .ct-background {
  width: 100%;
  height: calc(20vh - 50px);
  min-height: 200px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.ct-page-header > .ct-background > h4,
.ct-page-header > .ct-background > small {
  color: white;
}

.ct-page-header > .ct-background > h4 {
  font-size: 28px;
}

.ct-minted-animations {
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  left: -100px;
  width: 120%;
  height: 120%;
  background: black;
  z-index: -10;
}

.ct-minted-animations > div.ct-list {
  display: flex;
  width: 150vw;
  height: 150vh;
  height: auto;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.ct-minted-animations > div.ct-list > div {
  overflow: hidden;
  width: 180px;
  height: 180px;
}

.ct-minted-animations > div.ct-list > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.ct-minted-shadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.771);
  z-index: -5;
}

.ct-universal-minter {
  margin-bottom: 400px;
}

.ct-universal-minter-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  color: white;
  margin-bottom: 250px;
}

.ct-universal-minter .ct-soft-mint {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 350px;
  width: 100%;
  background: rgba(255, 255, 255, 0.853);
  color: black;
  padding: 25px;
  height: auto;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.ct-universal-minter:hover .ct-soft-mint {
  box-shadow: 0 14px 28px rgba(255, 255, 255, 0.25),
    0 10px 10px rgba(255, 255, 255, 0.22);
}

.ct-universal-minter .ct-last-minted-collections,
.ct-universal-minter .ct-last-minted {
  max-width: 300px;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 20px;
}

.ct-universal-minter .ct-last-minted-collections > h5,
.ct-universal-minter .ct-last-minted > h5 {
  text-align: center;
  max-width: 100%;
  text-transform: lowercase;
  font-size: 20px;
}

.ct-universal-minter .ct-last-minted > .ct-minted {
  display: flex;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  align-items: center;
  margin-bottom: 10px;
}

.ct-universal-minter .ct-last-minted-collections > .ct-minted:hover,
.ct-universal-minter .ct-last-minted > .ct-minted:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ct-universal-minter .ct-last-minted > .ct-minted > img {
  flex: 0 0 40%;
  max-width: 40%;
}

.ct-universal-minter .ct-last-minted-collections > .ct-minted {
  display: flex;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  height: 100px;
}

.ct-universal-minter .ct-last-minted-collections > .ct-minted {
  justify-content: center;
  align-items: center;
}

.ct-universal-minter .ct-last-minted-collections > .ct-minted > img {
  position: absolute;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  z-index: -1;
}

.ct-universal-minter .ct-last-minted > .ct-minted > div {
  padding-left: 20px;
}

.ct-universal-minter .ct-last-minted-collections > .ct-minted > div {
  text-align: center;
}

.ct-universal-minter .ct-last-minted-collections > .ct-minted > div > h3 {
  text-transform: lowercase;
}

.ct-universal-minter .ct-last-minted > .ct-minted > div > small {
  font-size: 9px;
  margin-left: 2px;
  display: block;
}

.ct-universal-minter .ct-last-minted > .ct-minted > div > h3 {
  text-transform: lowercase;
}

.ct-universal-minter .ct-last-minted > .ct-minted > div > h5 {
  font-size: 22px;
  display: block;
  margin-top: -3px;
  text-transform: lowercase;
}

.ct-universal-minter .ct-last-minted-collections > .ct-minted > div > h5 {
  font-size: 12px;
  text-transform: lowercase;
}

.ct-universal-minter .ct-last-minted-collections > .ct-minted > div > a,
.ct-universal-minter .ct-last-minted > .ct-minted > div > a {
  font-size: 10px;
  display: block;
}

.ct-minted-collection {
  position: relative;
  text-align: center;
}

.ct-minted-collection > span {
  position: absolute;
  top: 5px;
  right: 5px;
}

.ct-minted-collection > span.ct-protected {
  color: rgb(27, 170, 27);
}

.ct-minted-collection > span.ct-unprotected {
  color: rgb(208, 49, 38);
}

.ct-minted-collection > span.ct-official {
  color: rgb(191, 214, 38) !important;
}

.ct-universal-minter .ct-last-minted-collections h6 {
  font-size: 12px;
  font-weight: 400;
}

.ct-universal-minter
  .ct-last-minted-collections
  > .ct-minted.ct-minted-collection.ct-protected {
  box-shadow: 0 1px 3px rgba(23, 169, 35, 0.583),
    0 1px 2px rgba(53, 172, 47, 0.583);
}

.ct-universal-minter
  .ct-last-minted-collections
  > .ct-minted.ct-minted-collection.ct-unprotected {
  box-shadow: 0 1px 3px rgba(169, 23, 23, 0.583),
    0 1px 2px rgba(172, 47, 47, 0.583);
}

.ct-universal-minter
  .ct-last-minted-collections
  > .ct-minted.ct-minted-collection.ct-official {
  box-shadow: 0 1px 3px rgba(235, 212, 35, 0.583),
    0 1px 2px rgba(230, 223, 30, 0.583);
}

.ct-minted-collection > p {
  font-size: 10px;
  position: absolute;
  bottom: 5px;
}

@media screen and (min-width: 600px) {
  .ct-universal-minter .ct-last-minted-collections,
  .ct-universal-minter .ct-last-minted {
    margin-left: 20px;
  }
}

.ct-universal-minter .ct-soft-mint > small {
  font-size: 12px;
}

.ct-universal-minter .ct-soft-mint > p {
  font-size: 10px;
}

.ct-universal-minter .ct-soft-mint > .ct-new-nft {
  margin-top: 15px;
  width: 100%;
}

.ct-universal-minter .ct-soft-mint > .ct-new-nft > .ct-normal-data {
  display: flex;
  flex-direction: column;
  margin-bottom: 7px;
}

.ct-universal-minter .ct-soft-mint > .ct-new-nft > .ct-normal-data > div {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-variant: small-caps;
  margin-top: 5px;
}

input {
  border: 0;
  border-bottom: 1px solid black;
  height: 30px;
  text-align: center;
  background: transparent;
}

.ct-token-selector > div {
  border: 0;
  border-bottom: 1px solid black;
  border-radius: 0;
}

.ct-token-selector > div > div > div {
  text-transform: uppercase;
  font-size: 10px;
}

input:focus,
input:active,
input:hover {
  background-color: transparent;
}

.ct-universal-minter
  .ct-soft-mint
  > .ct-new-nft
  > .ct-normal-data
  > div
  > label {
  font-size: 14px;
  text-transform: lowercase;
  margin-bottom: 3px;
}

.ct-universal-minter
  .ct-soft-mint
  > .ct-new-nft
  > .ct-normal-data
  > div
  > small {
  font-size: 10px;
  text-transform: lowercase;
  line-height: 10px;
  font-variant: none;
  font-weight: lighter;
  margin-top: 5px;
}

.ct-universal-minter .ct-soft-mint > .ct-new-nft > .ct-image-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ct-universal-minter .ct-soft-mint > .ct-new-nft > .ct-image-selector > button {
  flex: 0 0 49%;
  max-width: 49%;
  text-transform: uppercase;
  font-size: 10px;
}

.ct-universal-minter
  .ct-soft-mint
  > .ct-new-nft
  > .ct-image
  > .ct-url
  > .ct-urls {
  margin: 10px 0;
}

.ct-universal-minter
  .ct-soft-mint
  > .ct-new-nft
  > .ct-image
  > .ct-url
  > .ct-urls
  > input {
  width: 100%;
  border: 0;
  border-bottom: 1px solid black;
  height: 30px;
  padding: 0 7px;
}

.ct-go-to-mint {
  position: relative;
  width: 100%;
}

.ct-go-to-mint button.ct-main-button {
  margin: 5px 0;
  text-transform: uppercase;
  font-size: 10px;
}

.ct-go-to-mint .ct-connect {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.ct-go-to-mint .ct-connect > button {
  text-transform: uppercase;
  font-weight: lighter;
  font-size: 12px;
  margin-top: 5px;
  width: 100%;
}

.ct-go-to-mint a {
  width: 100%;
}

.ct-go-to-mint .ct-go-to {
  margin-top: 10px;
  width: 100%;
}

.ct-go-to-mint span.ct-action {
  position: absolute;
  left: calc(50% - 20px);
  font-size: 45px;
  top: 50px;
}

.ct-universal-minter .ct-soft-mint > .ct-new-nft > .ct-image > .ct-dropzone {
  display: flex;
  flex-direction: column;
  margin-bottom: 7px;
}

.ct-universal-minter
  .ct-soft-mint
  > .ct-new-nft
  > .ct-image
  > .ct-dropzone
  > strong,
.ct-universal-minter
  .ct-soft-mint
  > .ct-new-nft
  > .ct-image
  > .ct-dropzone
  > small {
  font-size: 12px;
}

.ct-universal-minter .ct-selected-images {
  overflow: hidden;
  width: 100%;
}

.ct-universal-minter .ct-selected-images > .ct-nft-creator {
  width: 100%;
}

.ct-universal-minter
  .ct-selected-images
  > .ct-nft-creator
  > .ct-selected-image
  > img {
  width: 100%;
  border-radius: 10px;
  height: 300px;
  object-fit: cover;
  object-position: center;
}

.ct-universal-minter .ct-selected-images > .ct-nft-creator > .ct-preview {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  margin-bottom: 15px;
}

.ct-universal-minter
  .ct-selected-images
  > .ct-nft-creator
  > .ct-preview
  > small {
  font-size: 12px;
  color: grey;
}

.ct-universal-minter
  .ct-selected-images
  > .ct-nft-creator
  > .ct-preview
  > span {
  font-size: 14px;
  font-weight: bold;
}

.ct-universal-minter .ct-selected-images > .ct-nft-creator > .ct-data {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 0 10px;
}

.ct-universal-minter .ct-selected-images > .ct-nft-creator > .ct-data > div {
  display: flex;
  flex-direction: column;
}

.ct-universal-minter
  .ct-selected-images
  > .ct-nft-creator
  > .ct-data
  > div
  > label {
  font-size: 14px;
  font-weight: bold;
  margin-top: 7px;
}

.ct-universal-minter
  .ct-selected-images
  > .ct-nft-creator
  > .ct-data
  > div
  > input {
  border: 0;
  border-bottom: 1px solid black;
  padding: 0 10px;
  height: 30px;
}

.ct-universal-minter
  .ct-selected-images
  > .ct-nft-creator
  > .ct-data
  > div
  > button {
  width: 100%;
  margin-top: 2px;
}

.ct-add-meta-data > button {
  width: calc(100% - 10px);
  margin: 0 5px;
}

.ct-universal-minter .ct-soft-mint > .ct-actions {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.ct-universal-minter .ct-soft-mint > .ct-actions > p {
  font-size: 10px;
  margin-bottom: 10px;
}

.ct-universal-minter .ct-soft-mint > .ct-actions > .ct-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ct-universal-minter .ct-soft-mint > .ct-actions > .ct-buttons > button {
  flex: 0 0 49%;
  max-width: 49%;
  text-transform: uppercase;
  font-size: 10px;
}

.ct-image-dropper {
  width: 100%;
  border: 2px dotted #1c6ea4;
  height: 200px;
  margin: 10px 0;
  font-size: 14px;
}

.ct-image-dropper > div {
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* -------------------------------------------------------------------------- */
/*                                ANCHOR My NFTs                              */
/* -------------------------------------------------------------------------- */

.ct-soft-nfts,
.ct-minted-nfts {
  width: 100%;
  padding: 20px;
}

.ct-my-nfts-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  width: 90%;
  margin: 0 auto;
  min-width: 250px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.ct-my-nfts-container:hover {
  box-shadow: 0 14px 28px rgba(255, 255, 255, 0.25),
    0 10px 10px rgba(255, 255, 255, 0.22);
}

.ct-my-nfts-container > .ct-soft-nfts > h4,
.ct-my-nfts-container > .ct-minted-nfts > h4 {
  padding: 0 20px;
  font-size: 25px;
}

.ct-my-nfts-container .ct-collections {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex: 0 0 100%;
  justify-content: center;
  align-items: center;
}

.ct-my-nfts-container .ct-collections > .ct-collection-grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  justify-content: center;
  align-items: center;
}

.ct-my-nfts-container
  .ct-collections
  > .ct-collection-grid
  > .ct-collection-header {
  border-bottom: 1px solid grey;
  height: 30px;
  width: calc(100%);
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
  font-weight: lighter;
  flex: 0 0 100%;
  max-width: 100%;
  margin: 0;
  font-size: 14px;
}

.ct-collection-grid .ct-nft-data {
  flex: 0 0 100%;
  max-width: 100%;
  max-width: 200px;
  overflow: hidden;
  margin: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 20px;
  position: relative;
}

.ct-collection-grid .ct-nft-data:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ct-collection-grid .ct-nft-data img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.ct-collection-grid > .ct-nft-data > .ct-info {
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 12px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.539);
  color: white;
}
.ct-collection-grid .ct-nft-data > .ct-info > div {
  display: flex;
  justify-content: space-between;
}

.ct-collection-grid .ct-nft-data > .ct-info > div > div {
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  text-align: left;
}

.ct-collection-grid .ct-nft-data > .ct-info > div > div:nth-child(2) {
  align-items: center;
}

.ct-collection-grid .ct-nft-data > .ct-info > div > div > a {
  color: white;
  font-size: 16px;
}

.ct-collection-grid .ct-nft-data > .ct-info > .ct-metadata {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.ct-collection-grid .ct-nft-data > .ct-info > a {
  font-size: 10px;
  margin-top: 10px;
  display: block;
  text-align: center;
}

.ct-collection-grid .ct-nft-data > .ct-info > button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  background: black;
  padding: 3px;
  text-transform: uppercase;
  border-radius: 5px;
  font-size: 10px;
  cursor: pointer;
}

.ct-collection-grid .ct-nft-data > .ct-info > button:hover {
  background: grey;
}

.ct-collection-grid .ct-nft-data > .ct-info > button:disabled {
  background: green;
}

/* Collection */

.ct-collection-container {
  background: transparent;
  width: 100%;
  overflow: hidden;
}

.ct-collection-container.ct-admin {
  max-width: 750px;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
}

.ct-collection-container.ct-admin > .ct-image {
  width: 1005;
  height: 300px;
}

.ct-collection-container.ct-admin > .ct-image > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.ct-collection-container.ct-admin > .ct-collection-presentation {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 15px;
}

.ct-collection-container.ct-admin > .ct-collection-presentation > div {
  flex: 0 0 50%;
  max-width: 50%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  max-width: 300px;
  width: 90%;
  min-width: 250px;
  margin: 10px;
  justify-content: center;
}

.ct-collection-container.ct-admin
  > .ct-collection-presentation
  > div.ct-update
  > h4 {
  text-align: center;
  font-size: 20px;
}

.ct-collection-container.ct-admin
  > .ct-collection-presentation
  > div.ct-update
  > span {
  font-size: 12px;
  width: 90%;
  margin: 0 auto;
}

.ct-collection-container.ct-admin
  > .ct-collection-presentation
  > div.ct-update
  > div {
  width: 100%;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 4px;
  align-items: center;
}

.ct-collection-container.ct-admin
  > .ct-collection-presentation
  > div.ct-update
  > div
  > input {
  flex: 0 0 80%;
  max-width: 80%;
}

.ct-collection-container.ct-admin
  > .ct-collection-presentation
  > div.ct-update
  > div
  > button {
  flex: 0 0 20%;
  max-width: 20%;
}

.ct-collection-container .ct-banner {
  height: 250px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: -5;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
}

.ct-collection-container .ct-banner > div {
  display: flex;
  width: 150vw;
  justify-content: center;
  height: 100%;
}

.ct-collection-container .ct-banner > div > img {
  flex: 0 0 16.666%;
  width: 16.666%;
  object-fit: cover;
  object-position: center;
  opacity: 0.5;
  min-width: 200px;
  height: 100%;
}

.ct-collection-container > div.ct-data-container {
  padding-top: 150px;
  width: 90%;
  margin: 0 auto;
  max-width: 800px;
}

.ct-collection-container > div.ct-data-container > .ct-data {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  position: relative;
}

.ct-collection-container:hover > div.ct-data-container > .ct-data {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ct-collection-container > div.ct-data-container .ct-data .ct-image {
  width: 100%;
  height: 400px;
  position: relative;
}

.ct-collection-container
  > div.ct-data-container
  .ct-data
  .ct-image
  > .ct-collection-links {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  background: rgba(0, 0, 0, 0.567);
  padding: 15px;
}

.ct-collection-container
  > div.ct-data-container
  .ct-data
  .ct-image
  > .ct-collection-links
  > a {
  margin: 5px;
}

.ct-collection-container
  > div.ct-data-container
  .ct-data
  .ct-image
  > .ct-collection-links
  > a
  > span {
  color: white;
  font-size: 30px;
}

.ct-collection-container > div.ct-data-container .ct-data .ct-image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.ct-collection-container > div.ct-data-container > .ct-data > .ct-info {
  background-color: rgba(255, 255, 255, 0.857);
  display: flex;
  flex-direction: column;
  min-width: 250px;
  width: 80%;
  max-width: 300px;
  position: absolute;
  top: 150px;
  left: 50px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 15px;
  z-index: 5;
}

.ct-collection-container > div.ct-data-container .ct-data:hover .ct-info {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ct-collection-container > div.ct-data-container .ct-data .ct-info > h4 {
  font-size: 35px;
}

.ct-collection-container
  > div.ct-data-container
  .ct-data
  .ct-info
  > h4
  > small {
  font-size: 12px;
}

.ct-collection-container > div.ct-data-container .ct-data .ct-info > small {
  font-size: 10px;
  margin-top: 3px;
}

.ct-collection-container > div.ct-data-container .ct-data .ct-info > span {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.ct-collection-container
  > div.ct-data-container
  .ct-data
  .ct-info
  > span
  > strong {
  margin-right: 3px;
}

.ct-collection-container > div.ct-data-container .ct-data .ct-info .ct-minting {
  display: flex;
  height: 40px;
  align-items: center;
}

.ct-collection-container
  > div.ct-data-container
  .ct-data
  .ct-info
  .ct-minting
  > input {
  flex: 0 0 70%;
  max-width: 70%;
  height: 100%;
  text-align: center;
  line-height: 40px;
}

.ct-collection-container
  > div.ct-data-container
  .ct-data
  .ct-info
  .ct-minting
  > button.ct-main-button {
  flex: 0 0 30%;
  max-width: 30%;
  font-size: 10px;
}

.ct-collection-container > div.ct-data-container .ct-data .ct-percentage {
  background: rgb(232, 232, 232);
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  border-bottom: 1px solid rgba(128, 128, 128, 0.279);
  position: relative;
  z-index: -5;
}

.ct-collection-container > div.ct-data-container .ct-data .ct-percentage > div {
  background: rgb(39, 226, 39);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.ct-collection-container > div.ct-data-container .ct-data .ct-percentage small {
  z-index: 5;
  font-weight: lighter;
}

.ct-collection-container > div.ct-data-container .ct-data .ct-gallery {
  margin-top: 20px;
  padding: 0 30px;
}

.ct-collection-container
  > div.ct-data-container
  .ct-data
  .ct-gallery
  .ct-each-slide {
  height: 250px;
}

.ct-collection-container
  > div.ct-data-container
  .ct-data
  .ct-gallery
  .ct-each-slide
  > img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.ct-collection-container
  > div.ct-data-container
  .ct-data
  .ct-collection-information
  > h4 {
  font-size: 25px;
}

.ct-collection-container
  > div.ct-data-container
  .ct-data
  .ct-collection-information {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.ct-collection-container
  > div.ct-data-container
  .ct-data
  .ct-collection-information
  div {
  text-align: justify;
  padding: 10px 30px;
  font-size: 14px;
}

.ct-collection-container
  > div.ct-data-container
  .ct-data
  .ct-collection-information
  p {
  font-size: 14px;
}

.ct-collection-container
  > div.ct-data-container
  .ct-data
  .ct-collection-information
  p.ct-owner-info {
  padding: 10px 30px;
}

.ct-collection-container .ct-protected-collection {
  background: green;
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-size: 12px;
  padding: 10px 20px;
  margin-bottom: 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ct-collection-container > .ct-save {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.ct-collection-container > .ct-save > button {
  margin-top: 20px;
  padding: 5px;
  cursor: pointer;
}

.ct-collection-container > .ct-collection-presentation,
.ct-collection-container > .ct-collection-information {
  width: 90%;
}

.ct-collection-container > .ct-collection-presentation {
  display: flex;
  flex-direction: column;
}

.ct-collection-container > .ct-collection-presentation > .ct-image {
  overflow: hidden;
  width: 100%;
  height: 300px;
}

.ct-collection-container > .ct-collection-presentation > .ct-image > img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.ct-collection-container > .ct-collection-presentation > .ct-info {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.ct-collection-container > .ct-collection-presentation > .ct-info > h4 {
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 5px;
}

.ct-collection-container > .ct-collection-presentation > .ct-info > span {
  font-size: 12px;
}

.ct-collection-container > .ct-collection-presentation > .ct-info > span {
  display: flex;
}

.ct-collection-container
  > .ct-collection-presentation
  > .ct-info
  > span
  > strong {
  text-transform: capitalize;
  margin-right: 5px;
}

.ct-collection-container > .ct-collection-presentation > .ct-info > small {
  font-size: 10px;
  margin-top: 5px;
}

.ct-address {
  text-overflow: ellipsis;
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  direction: rtl;
  font-size: 10px;
  font-weight: lighter;
  text-align: right;
  display: block;
}

.ct-collection-container .ct-update-collection {
  flex: 0 0 100%;
  margin-top: 25px;
  padding: 20px;
}

.ct-collection-container .ct-update-collection > div {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.ct-collection-container .ct-update-collection > div > label {
  font-weight: bold;
  font-size: 14px;
}

.ct-collection-container .ct-update-collection > div .ct-dropzone {
  display: flex;
  flex-direction: column;
}

.ct-collection-container .ct-update-collection > div > small {
  font-size: 12px;
}

.ct-collection-container .ct-update-collection .ct-gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ct-collection-container .ct-update-collection .ct-gallery > img {
  flex: 0 0 20%;
  max-width: 20%;
}

.ct-collection-container .ct-project-links {
  display: flex;
  flex-direction: column;
}

.ct-collection-container .ct-project-links > div {
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  border-bottom: 1px solid black;
  margin-bottom: 10px;
}

.ct-collection-container .ct-project-links > div > input {
  border: 0;
  width: calc(100% - 30px);
  text-align: center;
  height: 30px;
}

.ct-collection-container .ct-project-links > div > div {
  width: 30px;
}

.ct-collection-container > .ct-collection-information {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.ct-collection-container > .ct-collection-information > h4 {
  font-size: 14px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.ct-collection-container > .ct-collection-information > span {
  font-size: 12px;
  text-transform: capitalize;
  margin-top: 5px;
  text-align: left;
  width: 90%;
  font-family: "Roboto Condensed", sans-serif;
}

.ct-collection-container > .ct-collection-information > div.ct-update-property {
  display: flex;
  width: 90%;
}

.ct-collection-container
  > .ct-collection-information
  > div.ct-update-property
  > input {
  flex: 0 0 80%;
  border: 0;
  border-bottom: 1px solid black;
  text-align: center;
}

.ct-collection-container
  > .ct-collection-information
  > div.ct-update-property
  > button {
  flex: 0 0 20%;
  border-radius: 0;
}

.ct-collection-container > .ct-collection-information > .ct-collection-links {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
}

.ct-collection-container
  > .ct-collection-information
  > .ct-collection-links
  > a {
  flex: 0 0 10%;
  max-width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  text-decoration: none;
  border-bottom: 0;
}

.ct-collection-container > .ct-collection-information .ct-owner-info {
  font-size: 12px;
  width: 90%;
  text-align: center;
}

.ct-pages-links {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  text-align: center;
}

.ct-pages-links > a {
  margin-bottom: 2px;
}

.ct-about-the-project {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.ct-about-the-project > h4 {
  margin-bottom: 10px;
}

.ct-about-the-project .ct-gallery {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.ct-about-the-project .ct-gallery > img {
  flex: 0 0 25%;
  max-width: 25%;
  overflow: hidden;
}

.ct-about-the-project .ct-about-project {
  text-align: justify;
  padding: 20px;
  font-size: 14px;
}

/* Keys */

.ct-my-keys {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.ct-my-keys > .ct-key-information {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  max-width: 700px;
  width: 90%;
  display: flex;
  flex-direction: row;
  padding: 15px 20px;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}

.ct-my-keys > .ct-key-information > .ct-image {
  overflow: hidden;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  max-width: 60px;
  max-height: 60px;
}

.ct-my-keys > .ct-key-information > .ct-image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.ct-my-keys > .ct-key-information > .ct-title {
  flex: 0 0 calc(100% - 70px);
  min-width: 150px;
  max-width: 200px;
}

.ct-my-keys > .ct-key-information > .ct-title h4 {
  font-size: 25px;
}

.ct-my-keys > .ct-key-information > .ct-title h4 > small {
  font-size: 12px;
}

.ct-my-keys > .ct-key-information > .ct-minting,
.ct-my-keys > .ct-key-information > .ct-title {
  padding: 10px;
}

.ct-my-keys > .ct-key-information > .ct-mint {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
}

.ct-my-keys > .ct-key-information > .ct-mint small {
  text-align: center;
  font-size: 10px;
  width: 100%;
}

.ct-my-keys > .ct-key-information > .ct-minting {
  width: 260px;
  flex: 0 0 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ct-my-keys > .ct-key-information > .ct-minting small,
.ct-my-keys > .ct-key-information > .ct-minting ul {
  font-size: 12px;
  padding-bottom: 10px;
  display: block;
}

.ct-my-keys > .ct-key-information > .ct-minting > div {
  min-width: 250px;
  width: 50%;
}

.ct-my-keys > .ct-key-information > .ct-minting .ct-tokenomics ul > li {
  list-style: none;
  display: flex;
  align-items: center;
}

.ct-my-keys > .ct-key-information > .ct-key-adventages {
  font-size: 12px;
  padding: 0 20px 10px 20px;
}

.ct-my-keys > .ct-key-information > .ct-key-adventages > ul {
  list-style: circle;
}

.ct-my-keys > .ct-key-information > .ct-key-adventages > ul > li {
  margin-top: 5px;
}

/* My collections */

.ct-my-collections .ct-collections-container {
  display: flex;
  background: white;
  width: 90%;
  flex-wrap: wrap;
  margin: 0 auto;
  min-width: 250px;
  justify-content: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 20px;
}

.ct-my-collections .ct-collections-container > h4 {
  flex: 0 0 100%;
  text-align: center;
  font-size: 22px;
  margin-bottom: 15px;
}

.ct-my-collections:hover .ct-collections-container {
  box-shadow: 0 14px 28px rgba(255, 255, 255, 0.25),
    0 10px 10px rgba(255, 255, 255, 0.22);
}

.ct-my-collections .ct-collections-container > div {
  overflow: hidden;
  max-width: 220px;
  width: 90%;
  height: 150px;
  position: relative;
  box-shadow: 0 19px 38px rgba(204, 156, 35, 0.3),
    0 15px 12px rgba(198, 147, 35, 0.22);
  margin: 10px;
}

.ct-my-collections .ct-collections-container > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.ct-my-collections .ct-collections-container > div > div {
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  background: rgba(0, 0, 0, 0.393);
  text-align: center;
}

.ct-my-collections .ct-collections-container > div > div > span {
  position: absolute;
  top: 7px;
  right: 7px;
}

.ct-my-collections .ct-collections-container > div.ct-protected {
  box-shadow: 0 19px 38px rgba(75, 201, 59, 0.3),
    0 15px 12px rgba(57, 185, 67, 0.22);
}

.ct-my-collections .ct-collections-container > div > div > a {
  font-size: 10px;
  margin-bottom: 1px;
  color: white;
  font-weight: lighter;
  text-transform: uppercase;
}

.ct-minting-test {
  max-width: 300px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  max-width: 300px;
  width: 90%;
}

.ct-minting-test h4 {
  text-align: center;
  margin-bottom: 10px;
}

.ct-minting-test small {
  font-size: 10px;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 10px;
}

.ct-minting-test input {
  text-align: center;
  width: 100%;
  border: 0;
  border-bottom: 1px solid black;
  height: 30px;
}

.ct-minting-test button {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 5px;
}

.ct-minting-test div > img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.ct-minting-test div h4 {
  font-size: 14px;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 10px;
}

.ct-my-collection-nfts {
  text-align: center;
}

.ct-my-collection-nfts > h4 {
  font-size: 25px;
}

.ct-my-collection-nfts .ct-collection-grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.ct-my-collection-nfts .ct-collection-grid > div.ct-nft-data {
  max-width: 170px;
}

.ct-staking-config {
  max-width: 300px;
  padding: 10px;
}

.ct-staking-config small {
  line-height: 10px;
  font-size: 9px;
  width: 100%;
}

.ct-staking-config > button {
  width: 100%;
  margin-top: 5px;
}

.ct-collection-container .ct-staking-pool {
  flex: 0 0 100%;
  background: rgb(45, 108, 202);
  color: white;
  padding: 7px 10px;
  margin-top: 10px;
  border-radius: 3px;
}

.ct-collection-container .ct-staking-pool a,
.ct-collection-container .ct-staking-pool p {
  color: white;
  font-weight: bold;
  font-size: 12px;
  display: block;
  margin-right: 10px;
}

/* Staking pools */

.ct-token-option {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
}

.ct-token-option img {
  width: 20px;
  margin-right: 5px;
}

.ct-create-staking-pool {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.ct-create-staking-pool > .ct-staking {
  background: white;
  padding: 20px;
}

.ct-create-staking-pool > .ct-staking > .ct-header > h4 {
  font-size: 20px;
}

.ct-create-staking-pool > .ct-staking .ct-config {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.ct-create-staking-pool > .ct-staking .ct-config > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
}

.ct-create-staking-pool > .ct-staking .ct-config > p {
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
}

.ct-create-staking-pool > .ct-staking .ct-config > div > label {
  font-size: 12px;
  line-height: 12px;
  text-transform: uppercase;
  font-weight: bold;
}

.ct-create-staking-pool > .ct-staking .ct-config > div > input {
  width: 100%;
}

.ct-create-staking-pool small,
.ct-create-staking-pool p {
  font-size: 11px;
  line-height: 11px;
}

.ct-create-staking-pool a {
  font-size: 12px;
}

.ct-create-staking-pool button {
  max-width: 150px;
}

.ct-create-staking-pool ul li {
  line-height: 13px;
}

/* NFT Collections */

.ct-drawn-cities > img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
  z-index: -10;
}

.ct-drawn-cities > .ct-call-to-action {
  width: 100%;
  height: calc(100vh - 100px);
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ct-drawn-cities > .ct-call-to-action > div {
  z-index: 9999;
  background: black;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 20px;
  position: relative;
  color: white;
}

.ct-drawn-cities > .ct-call-to-action:hover > div {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ct-drawn-cities > .ct-call-to-action:hover > div > div {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ct-drawn-cities > .ct-call-to-action > div > div {
  position: absolute;
  bottom: -60px;
  right: 30px;
  padding: 15px;
  background: white;
  color: black;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ct-drawn-cities > .ct-call-to-action > div > div > strong {
  font-size: 20px;
}

.ct-drawn-cities > .ct-minting {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 700px;
  margin-top: -40px;
  background: black;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  justify-content: center;
  padding: 20px;
}

.ct-drawn-cities > .ct-minting:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ct-drawn-cities > .ct-minting > .ct-minting-container {
  background: black;
  color: white;
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ct-drawn-cities > .ct-minting > .ct-minting-container > h4 {
  font-size: 25px;
  margin-bottom: 5px;
}

.ct-drawn-cities > .ct-minting > .ct-minting-container > input {
  background: transparent;
  color: white;
  border: 0;
  border-bottom: 1px solid white;
  height: 40px;
  margin-bottom: 5px;
  width: 100%;
  max-width: 150px;
  text-align: center;
}

.ct-drawn-cities > .ct-minting > .ct-minting-container > span,
.ct-drawn-cities > .ct-minting > .ct-minting-container > strong,
.ct-drawn-cities > .ct-minting > .ct-minting-container > small {
  font-size: 12px;
  max-width: 150px;
  width: 100%;
  text-align: center;
}

.ct-drawn-cities > .ct-minting > .ct-minting-container > small {
  font-size: 8px;
}

.ct-drawn-cities > .ct-minting > .ct-minting-container > button {
  background: white;
  color: black;
  max-width: 150px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 5px;
}

.ct-drawn-cities > .ct-minting > .ct-images {
  max-width: 300px;
  width: 100%;
}

.ct-drawn-cities > .ct-minting > .ct-images > img {
  width: 100%;
}

.ct-drawn-cities .ct-partners {
  display: flex;
  flex-wrap: wrap;
  margin-top: 100px;
  justify-content: center;
}

.ct-drawn-cities .ct-partners > div {
  max-width: 100px;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.ct-drawn-cities .ct-partners > div:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ct-drawn-cities .ct-partners > div > a {
  font-size: 10px;
}

.ct-drawn-cities .ct-partners > div > h3,
.ct-drawn-cities .ct-partners > div > h5 {
  font-size: 13px;
  text-align: center;
}

.ct-drawn-cities .ct-partners > div > img {
  width: 70px;
  height: 70px;
  object-position: center;
  object-fit: cover;
}

.ct-drawn-cities .ct-roadmap {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  max-width: 600px;
  margin: 100px auto 0 auto;
  z-index: 99;
}

.ct-drawn-cities .ct-roadmap > h4 {
  text-align: center;
  font-size: 25px;
}

.ct-drawn-cities .ct-roadmap > div {
  width: 60%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 15px;
  font-size: 12px;
  margin-top: -5px;
}

.ct-drawn-cities .ct-roadmap > div:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ct-drawn-cities .ct-roadmap > div:nth-child(2n) {
  background: white;
  color: black;
  margin-right: auto;
}

.ct-drawn-cities .ct-roadmap > div:nth-child(2n - 1) {
  background: black;
  color: white;
  margin-left: auto;
}

.ct-drawn-cities .ct-small-text {
  font-size: 8px !important;
  margin-bottom: 7px;
}

.ct-drawn-cities .ct-collection-grid {
  justify-content: center;
}

.ct-drawn-cities .ct-collection-grid > div {
  background: white;
}

/* Universal Battles */

.ct-battles-container {
  display: flex;
  flex-direction: row;
  position: relative;
}

.ct-battles-container > .ct-battle-data {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 250px;
  z-index: 99;
}

.ct-battles-container > .ct-battle-data > div {
  width: 100%;
  box-shadow: 0 5px 5px rgb(0 0 0 / 19%), 0 3px 3px rgba(0, 0, 0, 0.107);
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: visible;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: white;
  border-top: 1px solid grey;
}

.ct-battles-container > .ct-battle-data > .ct-copy > h4 {
  font-size: 12px;
  text-align: center;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ct-battles-container > .ct-battle-data > div::-webkit-scrollbar {
  display: none;
}

.ct-battles-container > .ct-battle-data > div > h4 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ct-battles-container > .ct-battle-data > .ct-party-members > h4 > small {
  font-size: 10px;
}

.ct-battles-container > .ct-battle-data > div > div.ct-close-bar {
  position: absolute;
  top: 0;
  right: 0;
  height: 35px;
  width: 35px;
  border: 0;
  background: white;
}

.ct-battles-container > .ct-battle-data > div.ct-rules {
  cursor: pointer;
}

.ct-battles-container > .ct-battle-data > .ct-party-members > div,
.ct-battles-container > .ct-battle-data > .ct-account > div,
.ct-battles-container > .ct-battle-data > .ct-prizes > div {
  display: flex;
  height: 40px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-bottom: 1px solid grey;
  padding: 5px 0;
  cursor: pointer;
  animation-duration: 1s;
  position: relative;
}

.ct-battles-container > .ct-battle-data > .ct-account > div.ct-data {
  flex-direction: column;
}

.ct-battles-container
  > .ct-battle-data
  > .ct-account
  > div.ct-data.ct-history
  > span {
  margin: 0;
  font-size: 10px;
}

.ct-battles-container > .ct-battle-data > .ct-account > div.ct-data > button {
  display: flex;
}

.ct-battles-container > .ct-battle-data > .ct-prizes > div {
  flex-direction: column;
}

.ct-battles-container
  > .ct-battle-data
  > .ct-party-members
  > div
  > img.ct-sword {
  position: absolute;
  right: -50px;
  animation-duration: 1s;
}

.ct-battles-container
  > .ct-battle-data
  > .ct-party-members
  > div:hover
  > img.ct-sword {
  right: calc(50% - 10px);
}

.ct-battles-container
  > .ct-battle-data
  > .ct-party-members
  > div
  > .ct-battle-shadow {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-fill-mode: forwards;
}

.ct-battles-container
  > .ct-battle-data
  > .ct-party-members
  > div
  > .ct-battle-shadow
  > img {
  border-radius: 0;
  opacity: 0;
  animation-fill-mode: forwards;
}

.ct-battles-container
  > .ct-battle-data
  > .ct-party-members
  > div:hover
  > .ct-battle-shadow {
  animation: backgroundChange 0.2s 1;
  animation-fill-mode: forwards;
}

.ct-battles-container
  > .ct-battle-data
  > .ct-party-members
  > div:hover
  > .ct-battle-shadow
  > img {
  animation: opacityChanges 1s 1;
  animation-fill-mode: forwards;
}

@keyframes backgroundChange {
  from {
    background-color: rgba(223, 223, 223, 0);
  }
  to {
    background-color: rgba(223, 223, 223, 0.668);
  }
}

@keyframes opacityChanges {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.ct-battles-container > .ct-battle-data > .ct-party-members > div img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.ct-battles-container > .ct-battle-data > .ct-party-members > div > span {
  width: 50px;
  text-align: center;
  font-variant: small-caps;
}

.ct-battles-container > .ct-battles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.ct-battles-container > .ct-battles > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ct-battle-field {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ct-battle-field > div.ct-team {
  background: rgba(0, 0, 0, 0.153);
  width: 100%;
  height: 50%;
  border-bottom: 1px solid white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.ct-battle-field > div.ct-battle-actions {
  position: absolute;
  width: 200px;
  height: 40px;
  left: calc(50% - 100px);
  top: calc(50% - 20px);
  border: 0;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ct-battle-field > div.ct-battle-actions > span {
  font-family: "Bangers", cursive;
  font-size: 80px;
  color: black;
  -webkit-animation: breathing 5s ease-out infinite normal;
  animation: breathing 5s ease-out infinite normal;
}

.ct-battle-field > div.ct-battle-actions > .ct-vs > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  font-size: 10px;
}

.ct-battle-field > div.ct-battle-actions > div {
  text-align: center;
  color: black;
  font-size: 12px;
  display: flex;
  flex-direction: column;
}

.ct-battle-field > div.ct-battle-actions > div > div {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  font-weight: 600;
  text-transform: uppercase;
}

@-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }

  60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}

.ct-battle-field > div > div {
  position: relative;
  width: 170px;
  margin: 0 2%;
}

.ct-battle-field > div div.ct-add-nft {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ct-battle-field > div div.ct-add-nft span {
  background: black;
  color: white;
  border-radius: 50%;
  margin-top: 5px;
  padding: 6px;
}

.ct-nft-selector-container {
  position: fixed;
  background: white;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 99;
  left: 0;
}

.ct-nft-selector {
  height: 100%;
  width: 100%;
  background: white;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}

.ct-nft-selector > .ct-available-nfts,
.ct-nft-selector > .ct-exit {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 250px;
  z-index: 99;
  width: 100%;
  box-shadow: 0 5px 5px rgb(0 0 0 / 19%), 0 3px 3px rgba(0, 0, 0, 0.107);
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: visible;
  position: relative;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: white;
  border-top: 1px solid grey;
}

.ct-nft-selector > .ct-exit {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 10px 0;
  cursor: pointer;
  font-size: 12px;
}

.ct-nft-selector > .ct-exit > span {
  margin-right: 5px;
}

.ct-nft-selector > .ct-available-nfts::-webkit-scrollbar,
.ct-nft-selector > .ct-exit::-webkit-scrollbar {
  display: none;
}

.ct-nft-selector > .ct-available-nfts > h4 {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px 0;
  cursor: pointer;
  position: relative;
}

.ct-nft-selector > .ct-available-nfts > h4 > span {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
}

.ct-nft-selector > .ct-available-nfts > div {
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.ct-nft-selector > .ct-available-nfts > div:hover {
  background: rgb(193, 193, 193);
}

.ct-nft-selector > .ct-available-nfts > div > img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  object-fit: cover;
  object-position: center;
  margin: 0 5px;
}

.ct-nft-selector > .ct-available-nfts > div > span {
  margin: 0 5px;
  font-variant: small-caps;
}

.ct-nft-selector > .ct-selector-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  margin-top: 60px;
}

.ct-nft-selector > .ct-selector-container > .ct-selector > h4 {
  font-size: 22px;
  padding-top: 15px;
}

.ct-nft-selector > .ct-selector-container > .ct-selector .ct-nfts-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 30px;
  overflow-y: scroll;
  max-height: calc(100% - 130px);
}

.ct-nft-selector
  > .ct-selector-container
  > .ct-selector
  .ct-nfts-list
  > .ct-loaded-nft {
  display: flex;
  width: 150px;
  overflow: hidden;
  height: 150px;
  box-shadow: 0 5px 5px rgb(0 0 0 / 19%), 0 3px 3px rgba(0, 0, 0, 0.107);
  cursor: pointer;
  flex-direction: column;
  margin: 5px;
  position: relative;
}

.ct-nft-selector
  > .ct-selector-container
  > .ct-selector
  .ct-nfts-list
  > .ct-loaded-nft.ct-invalid {
  box-shadow: none;
}

.ct-nft-selector
  > .ct-selector-container
  > .ct-selector
  .ct-nfts-list
  > .ct-loaded-nft
  > .ct-add-to-team {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  cursor: pointer;
  transition: all 0.2s ease;
}

.ct-nft-selector
  > .ct-selector-container
  > .ct-selector
  .ct-nfts-list
  > .ct-loaded-nft
  > .ct-add-to-team.ct-disabled-background {
  background: rgba(233, 56, 56, 0.572);
}

.ct-nft-selector
  > .ct-selector-container
  > .ct-selector
  .ct-nfts-list
  > .ct-loaded-nft
  > .ct-add-to-team:hover {
  background: rgb(196, 196, 196);
}

.ct-nft-selector
  > .ct-selector-container
  > .ct-selector
  .ct-nfts-list
  > .ct-loaded-nft
  > .ct-add-to-team
  > img {
  width: 25px;
  height: 25px;
}

.ct-nft-selector
  > .ct-selector-container
  > .ct-selector
  .ct-nfts-list
  > .ct-loaded-nft
  > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.ct-nft-selector
  > .ct-selector-container
  > .ct-selector
  .ct-nfts-list
  > .ct-loaded-nft
  > div {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 5px;
  height: 40%;
  flex-direction: column;
}

.ct-nft-selector
  > .ct-selector-container
  > .ct-selector
  .ct-nfts-list
  > .ct-loaded-nft
  > div
  > button {
  height: 20px;
  font-size: 10px;
  margin-top: 5px;
}

.ct-stats {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  margin-top: 2px;
}

.ct-stats > span {
  display: flex;
  width: 100%;
  margin-bottom: 2px;
  color: white;
  background: grey;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 15px;
  position: relative;
  font-weight: bold;
}

.ct-stats > span .ct-anim {
  position: absolute;
  right: -25px;
  font-family: "Bangers", cursive;
  color: black;
  letter-spacing: 1px;
  font-size: 20px;
  -webkit-animation: breathing 1s ease-out infinite normal;
  animation: breathing 1s ease-out infinite normal;
}

.ct-stats > span .ct-anim.ct-anim-reduce {
  color: red;
}

.ct-stats > span .ct-anim.ct-anim-increase {
  color: green;
}

.ct-stats > span > small {
  font-size: 10px;
  z-index: 5;
}

.ct-stats > span > span.ct-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.ct-stats > span:nth-child(1) > span.ct-bar {
  background-color: rgb(56, 156, 23);
}

.ct-stats > span:nth-child(2) > span.ct-bar {
  background-color: rgb(162, 10, 10);
}

.ct-stats > span:nth-child(3) > span.ct-bar {
  background-color: rgb(156, 120, 34);
}

.ct-field-nft {
  width: 100%;
  height: 100%;
  position: relative;
}

.ct-field-nft > span {
  position: absolute;
  top: 5px;
  right: 5px;
  background: white;
  padding: 5px;
  border-radius: 50%;
}

.ct-field-nft > span:hover {
  background: grey;
  color: white;
}

.ct-field-nft > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.ct-field-nft > .ct-data {
  position: absolute;
  top: -63px;
  left: 0;
  width: 100%;
}

.ct-battle-field > div > div.ct-join-to-party {
  position: absolute;
  width: 100%;
  justify-content: center;
  height: 100%;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.752);
  flex-direction: column;
  color: white;
}

.ct-battle-field > div > div.ct-join-to-party h4 {
  font-size: 30px;
}

.ct-battle-field > div > div.ct-join-to-party p,
.ct-battle-field > div > div.ct-join-to-party ul {
  max-width: 300px;
  text-align: justify;
  font-size: 11px;
  margin-bottom: 5px;
  width: 95%;
}

.ct-battle-field > div > div.ct-join-to-party ul > li a {
  color: white;
}

.ct-battle-field > div > div.ct-join-to-party > button {
  width: 100px;
  background: white;
  color: black;
}

.ct-join-buttons {
  display: flex;
  flex-direction: row;
}

.ct-join-buttons > button {
  font-size: 10px;
  padding: 5px 10px;
  width: 100px;
}

.ct-account-management,
.ct-balance-history {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.764);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ct-account-management.ct-rules-container .ct-data > ul {
  padding-left: 15px;
}

.ct-account-management.ct-rules-container .ct-data > ul > li {
  list-style: circle;
  margin-bottom: 5px;
}

.ct-account-management > .ct-actions,
.ct-balance-history > .ct-actions {
  width: 95%;
  max-width: 270px;
  background: white;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(255, 255, 255, 0.12),
    0 1px 2px rgba(255, 255, 255, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  font-size: 12px;
  display: flex;
  position: relative;
  flex-direction: column;
  max-height: 400px;
  overflow-y: scroll;
}

.ct-account-management:hover .ct-actions,
.ct-balance-history:hover .ct-actions {
  box-shadow: 0 14px 28px rgba(255, 255, 255, 0.25),
    0 10px 10px rgba(250, 250, 250, 0.22);
}

.ct-account-management .ct-actions > h4,
.ct-balance-history .ct-actions > h4 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 5px;
}

.ct-account-management > .ct-actions .ct-data {
  display: flex;
  flex-direction: column;
}

.ct-account-management > .ct-actions .ct-data .ct-team-data {
  display: flex;
  flex-direction: column;
}

.ct-account-management > .ct-actions .ct-data > div {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.ct-account-management > .ct-actions .ct-data > div > label {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 10px;
}

.ct-account-management > .ct-actions .ct-data > div > input {
  border: 0;
  border-bottom: 1px solid black;
  background: white;
  height: 30px;
  text-align: center;
}

.ct-account-management > .ct-actions p {
  font-size: 10px;
  line-height: 10px;
  margin-top: 5px;
}

.ct-account-management > .ct-actions button.ct-main-button {
  font-size: 8px;
  height: 25px;
  text-transform: uppercase;
}

.ct-account-management > .ct-actions .ct-close-account {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
}

.ct-little-click {
  margin-top: 3px;
  margin-bottom: 3px;
  text-decoration: underline;
  display: block;
}

.ct-under-attack {
  background: rgba(0, 0, 0, 0.746);
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ct-under-attack > .ct-container {
  max-width: 250px;
}

.ct-under-attack h4 {
  font-family: "Bangers", cursive;
  font-size: 55px;
  letter-spacing: 5px;
}

.ct-under-attack small {
  margin-bottom: 5px;
  display: block;
  text-align: center;
}

.ct-under-attack h5 {
  font-size: 18px;
}

.ct-under-attack button {
  margin-top: 10px;
}

.ct-attack-cards {
  position: fixed;
  bottom: -100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ct-attack-cards > .ct-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.ct-attack-cards > .ct-cards > div {
  width: 170px;
  height: 90%;
  height: 250px;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  margin: -5px;
}

.ct-attack-cards > .ct-cards > div:nth-child(1) {
  transform: rotate(-10deg);
}

.ct-attack-cards > .ct-cards > div:nth-child(3) {
  transform: rotate(10deg);
}

.ct-attack-cards > .ct-cards > div:nth-child(1):hover {
  transform: rotate(-10deg) translate(0, -100px);
}

.ct-attack-cards > .ct-cards > div:nth-child(2):hover {
  transform: translate(0, -100px);
}

.ct-attack-cards > .ct-cards > div:nth-child(3):hover {
  transform: rotate(10deg) translate(0, -100px);
}

.ct-attack-cards > .ct-cards > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.ct-attack-cards > .ct-cards > div > div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  background: rgba(255, 255, 255, 0.438);
  color: black;
}

.ct-attack-cards > .ct-cards > div > div > h5 {
  font-size: 22px;
  font-family: "Bangers", cursive;
  letter-spacing: 2px;
}

.ct-loader-container {
  background-color: rgba(0, 0, 0, 0.665);
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
}

.ct-loader-container .spinner {
  background-color: white;
}

.ct-winner-container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(21, 21, 21, 0.486);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  top: 0;
  left: 0;
}

.ct-winner-container > div {
  height: 150px;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  color: black;
  flex-direction: column;
  text-align: center;
  position: relative;
  box-shadow: 0 1px 3px rgba(255, 255, 255, 0.12),
    0 1px 2px rgba(255, 255, 255, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.ct-winner-container:hover > div {
  box-shadow: 0 14px 28px rgba(255, 255, 255, 0.25),
    0 10px 10px rgba(250, 250, 250, 0.22);
}

.ct-winner-container > div > h4 {
  font-family: "Bangers", cursive;
  font-size: 35px;
  letter-spacing: 2px;
}

.ct-winner-container > div > div {
  max-width: 80%;
}

.ct-winner-container > div > div > span {
  font-size: 12px;
}

.ct-winner-container > div > span {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.ct-tokenomics > div.ct-tokenomics {
  font-size: 10px;
  flex-direction: column;
  height: 60px;
}

.ct-check-selector {
  width: 100%;
  background-color: rgb(255, 60, 60);
  height: 30px;
  margin-top: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  text-transform: uppercase;
}

.ct-check-selector > div {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ct-check-selector.ct-active {
  background: rgb(19, 146, 19);
}

.ct-nfts-generation {
  font-size: 10px;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  height: 80px;
}

.ct-nfts-generation > div.ct-nfts-generation {
  height: 80px;
}

.ct-nfts-generation > div.ct-nfts-generation > button {
  height: 25px;
  width: 80%;
  font-size: 10px;
}

.ct-nfts-generation > div.ct-nfts-generation:hover {
  background: white;
}

.ct-universal-key {
  display: flex;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 10px;
  margin-top: 15px;
}

.ct-universal-key:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.ct-universal-key > div.ct-key {
  flex: 0 0 25%;
  max-width: 25%;
  object-fit: cover;
  object-position: center;
}

.ct-universal-key > div.ct-key > img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.ct-universal-key > div {
  flex: 0 0 75%;
  max-width: 75%;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ct-universal-key > div > h4 {
  font-size: 22px;
}

.ct-universal-key > div small,
.ct-universal-key > div a {
  font-size: 12px;
}

.ct-universal-key > div > button {
  text-transform: uppercase;
  font-size: 10px;
  margin-top: 3px;
}

.ct-promotor {
  margin-bottom: 55px;
}

.ct-promotor:hover > div {
  box-shadow: 0 14px 28px rgb(255 255 255 / 25%),
    0 10px 10px rgb(255 255 255 / 22%);
}

.ct-promotor > div {
  display: flex;
  background: white;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 90%;
  margin: 0 auto;
  height: 200px;
  max-width: 500px;
}

.ct-promotor > div > .ct-image {
  flex: 0 0 35%;
  max-width: 35%;
  overflow: hidden;
}

.ct-promotor > div > .ct-image > img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 100%;
}

.ct-promotor > div > .ct-data {
  display: flex;
  flex-direction: column;
  padding: 10px;
  justify-content: center;
}

.ct-promotor > div > .ct-data > h4 {
  font-size: 25px;
}

.ct-promotor > div > .ct-data > p {
  font-size: 15px;
  margin-top: 3px;
  margin-bottom: 5px;
}

.ct-collection-container .ct-tokenomics {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}

.ct-collection-container .ct-tokenomics > div.ct-bar {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(19, 165, 19, 0.655);
  margin: 0;
  padding: 0;
  height: 100%;
  z-index: 1;
  max-width: 100%;
}

.ct-collection-container .ct-tokenomics > div {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 250px;
  background: black;
  color: white;
  margin: 10px;
  padding: 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  z-index: 4;
}

.ct-collection-container .ct-tokenomics > div > h4 {
  font-size: 12px;
  text-transform: uppercase;
  padding: 5px;
  text-align: center;
}

.ct-collection-container .ct-tokenomics > div > div {
  display: flex;
  flex-direction: column;
  font-size: 11px;
}

.ct-collection-container .ct-tokenomics > div > div > span > strong {
  text-transform: uppercase;
}

.ct-collection-container .ct-whitelist {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.ct-collection-container .ct-whitelist > div {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid grey;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ct-collection-container .ct-whitelist > div > p {
  display: block;
  font-size: 12px;
}

.ct-collection-container .ct-whitelist > div > input {
  width: 70%;
  border-bottom: 0;
}

.ct-collection-container .ct-whitelist > div > button {
  width: 100px;
  height: 25px;
}

.ct-collection-container .ct-whitelist > div > label {
  font-size: 10px;
  flex: 0 0 100%;
  text-transform: uppercase;
  margin-top: 15px;
  font-weight: bold;
}

.ct-distributions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ct-distributions > div {
  background: white;
  padding: 15px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  width: 90%;
  max-width: 350px;
  margin: 15px;
}

.ct-distributions > div > div > button > span {
  margin-left: 5px;
}

.ct-distributions > div > div > small {
  font-size: 10px;
  line-height: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.ct-distributions > div > div {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.ct-distributions > div > div > h4 {
  font-size: 25px;
  margin-bottom: 5px;
}

.ct-distributions > div > div > h4 > small {
  font-size: 11px;
}

.ct-collections-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.ct-collections-list > div {
  background: white;
  width: 90%;
  max-width: 250px;
  overflow: hidden;
  height: 150px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  margin: 10px 10px;
}

.ct-collections-list > div > .ct-info {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.634);
  color: white;
  text-align: center;
  flex-direction: column;
}

.ct-collections-list > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  text-transform: uppercase;
}

.ct-collections-list > div > .ct-info > h3 {
  color: white;
  font-size: 30px;
  line-height: 20px;
  margin-bottom: 5px;
}

.ct-collections-list > div > .ct-info > a {
  color: white;
  font-size: 10px;
  text-transform: uppercase;
}

.ct-collections-list > div > .ct-info > p {
  font-size: 10px;
  color: white;
}

.ct-last-collections {
  display: flex;
  margin-bottom: 45px;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  flex-wrap: wrap;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
}

.ct-last-collections > h4 {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  text-align: center;
  font-size: 32px;
}

.ct-last-collections > div {
  width: 90%;
  flex: 0 0 calc(33.33333% - 40px);
  height: 300px;
  overflow: hidden;
  margin: 20px;
  position: relative;
  min-width: 130px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
}

.ct-last-collections > div > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.ct-last-collections > div > div {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.634);
  color: white;
}

.ct-last-collections > div > div > h4 {
  font-size: 35px;
}

.ct-keys-distribution {
  display: flex;
  flex-direction: row;
  background: white;
  margin: 0 auto;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
}

.ct-bidding-container {
  display: flex;
  flex-direction: column;
}

.ct-bidding-container > div {
  background: white;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  margin: 20px 0;
  padding: 15px;
}

.ct-bidding-container > div > h4 {
  font-size: 20px;
}

.ct-bidding-container > div > small {
  font-size: 12px;
  max-width: 300px;
  width: 100%;
  display: block;
}

.ct-bidding-container > div.ct-external-nfts ul {
  text-transform: uppercase;
  font-size: 10px;
  margin-left: 10px;
}

.ct-bidding-container > div.ct-external-nfts > .ct-bids {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-top: 25px;
}

.ct-bidding-container > div.ct-external-nfts > .ct-bids > div {
  max-width: 230px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  display: flex;
  margin: 10px;
}

.ct-bidding-container > div.ct-external-nfts > .ct-bids > div.ct-create-bid {
  max-width: 500px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  margin: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media screen and (min-width: 550px) {
  .ct-bidding-container > div.ct-external-nfts > .ct-bids > div > .ct-image {
    max-width: 250px;
  }

  .ct-bidding-container
    > div.ct-external-nfts
    > .ct-bids
    > div.ct-create-bid
    > .ct-data {
    max-width: 250px;
  }
}

.ct-bidding-container > div.ct-external-nfts > .ct-bids > div > .ct-image {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  min-width: 200px;
  height: 300px;
}

.ct-bidding-container
  > div.ct-external-nfts
  > .ct-bids
  > div
  > .ct-image
  > .ct-info {
  background: rgba(0, 0, 0, 0.34);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: white;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  text-transform: uppercase;
  font-size: 12px;
  height: 300px;
  padding: 10px;
}

.ct-bidding-container
  > div.ct-external-nfts
  > .ct-bids
  > div
  > .ct-image
  > .ct-bidding-info {
  background: rgba(0, 0, 0, 0.437);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: white;
  display: flex;
  text-transform: uppercase;
  font-size: 12px;
  padding: 10px;
  flex-direction: column;
}

.ct-bidding-container
  > div.ct-external-nfts
  > .ct-bids
  > div
  > .ct-image
  > .ct-bidding-info.ct-finished {
  background: rgba(109, 49, 49, 0.807);
}

.ct-bidding-container
  > div.ct-external-nfts
  > .ct-bids
  > div
  > .ct-image
  > .ct-bidding-info
  .ct-moment {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  width: 100%;
}

.ct-bidding-container
  > div.ct-external-nfts
  > .ct-bids
  > div
  > .ct-image
  > .ct-bidding-info
  .ct-moment
  > .ct-actions {
  position: absolute;
  right: 7px;
  top: 7px;
  font-size: 18px;
  color: white;
  cursor: pointer;
}

.ct-bidding-container
  > div.ct-external-nfts
  > .ct-bids
  > div
  > .ct-image
  > .ct-bidding-info
  .ct-moment
  > .ct-actions
  > a,
.ct-bidding-container
  > div.ct-external-nfts
  > .ct-bids
  > div
  > .ct-image
  > .ct-bidding-info
  .ct-moment
  > .ct-actions
  > span {
  color: white;
  margin-left: 5px;
}

.ct-bidding-container
  > div.ct-external-nfts
  > .ct-bids
  > div
  > .ct-image
  > .ct-bidding-info
  .ct-bidding {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 11px;
}

.ct-bidding-container
  > div.ct-external-nfts
  > .ct-bids
  > div
  > .ct-image
  > .ct-bidding-info
  .ct-bidding
  .ct-main-button.ct-winner-button {
  background-color: green;
  cursor: not-allowed;
}

.ct-bidding-container
  > div.ct-external-nfts
  > .ct-bids
  > div
  > .ct-image
  > .ct-bidding-info
  .ct-moment
  > h4 {
  font-size: 12px;
  text-transform: uppercase;
  margin-top: 25px;
}

.ct-bidding-container
  > div.ct-external-nfts
  > .ct-bids
  > div
  > .ct-image
  > img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.ct-bidding-container
  > div.ct-external-nfts
  > .ct-bids
  > div.ct-create-bid
  > .ct-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-width: 200px;
  width: 100%;
}

.ct-bidding-container
  > div.ct-external-nfts
  > .ct-bids
  > div.ct-create-bid
  > .ct-data
  > div {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
}

.ct-bidding-container
  > div.ct-external-nfts
  > .ct-bids
  > div.ct-create-bid
  > .ct-data
  > div
  > label {
  font-weight: bold;
  font-size: 10px;
  width: 100%;
  text-transform: uppercase;
  margin-bottom: 3px;
}

/* Material UI */

.MuiSlider-colorSecondary {
  color: black;
}

.ct-ciervo-lab {
  width: 100%;
  background: black;
}

.ct-ciervo-lab > .ct-banner-container {
  height: 350px;
  background: transparent;
  position: relative;
  margin-top: -100px;
  display: none;
}

.ct-ciervo-lab > .ct-banner-container > .ct-banner {
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.ct-ciervo-lab .ct-call-to-action {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
  position: relative;
  padding-top: 100px;
  z-index: 99;
  padding-bottom: 50px;
}

.ct-ciervo-lab .ct-call-to-action > .ct-animated-square {
  position: absolute;
  height: 100%;
  width: 40px;
  margin-top: -50px;
  z-index: 50;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 49%,
    rgba(255, 255, 255, 0) 100%
  );
}

.ct-ciervo-lab .ct-call-to-action > .ct-animated-square:nth-child(1) {
  left: 10%;
}

.ct-ciervo-lab .ct-call-to-action > .ct-animated-square:nth-child(2) {
  left: 20%;
}

.ct-ciervo-lab .ct-call-to-action > .ct-animated-square:nth-child(3) {
  left: 30%;
}

.ct-ciervo-lab .ct-call-to-action > .ct-animated-square:nth-child(4) {
  left: 40%;
}

.ct-ciervo-lab .ct-call-to-action > .ct-animated-square:nth-child(5) {
  left: 50%;
}

.ct-ciervo-lab .ct-call-to-action > .ct-animated-square:nth-child(6) {
  left: 60%;
}

.ct-ciervo-lab .ct-call-to-action > .ct-animated-square:nth-child(7) {
  left: 70%;
}

.ct-ciervo-lab .ct-call-to-action > .ct-animated-square:nth-child(8) {
  left: 80%;
}

.ct-ciervo-lab .ct-call-to-action > .ct-animated-square:nth-child(9) {
  left: 90%;
}

.ct-ciervo-lab .ct-call-to-action > .ct-animated-square:nth-child(4) {
  left: 40%;
}

.ct-ciervo-lab .ct-call-to-action > .ct-minting {
  background: black;
  max-width: 600px;
  display: flex;
  margin-top: -50px;
  z-index: 99;
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.19),
    0 6px 6px rgba(255, 255, 255, 0.23);
  flex-wrap: wrap;
  justify-content: center;
}

.ct-ciervo-lab .ct-call-to-action > .ct-minting > div {
  overflow: hidden;
  min-width: 200px;
  max-width: 300px;
}

.ct-ciervo-lab .ct-call-to-action > .ct-minting > div.ct-minting-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 25px;
  text-transform: uppercase;
  color: white;
}

.ct-ciervo-lab
  .ct-call-to-action
  > .ct-minting
  > div.ct-minting-container
  span {
  font-size: 12px;
}

.ct-ciervo-lab
  .ct-call-to-action
  > .ct-minting
  > div.ct-minting-container
  input {
  border-bottom: 1px solid white;
  color: white;
  margin-bottom: 10px;
  height: 40px;
  width: 150px;
}

.ct-ciervo-lab
  .ct-call-to-action
  > .ct-minting
  > div.ct-minting-container
  button {
  background: white;
  color: black;
  width: 150px;
  text-transform: uppercase;
  margin-top: 5px;
}

.ct-ciervo-lab .ct-call-to-action > .ct-minting > div.ct-animation {
  height: 300px;
  width: 100%;
}

.ct-ciervo-lab .ct-call-to-action > .ct-minting > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
}

.ct-ciervo-lab .ct-call-to-action > .ct-main-banner {
  max-width: 400px;
  display: flex;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.19),
    0 6px 6px rgba(255, 255, 255, 0.23);
  z-index: 99;
  background-color: rgb(0, 0, 0);
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  z-index: 101;
  margin-top: -30px;
}

@media screen and (min-width: 400px) {
  .ct-ciervo-lab .ct-call-to-action > .ct-minting > div {
    flex: 0 0 50%;
  }
}

@media screen and (min-width: 800px) {
  .ct-ciervo-lab .ct-call-to-action > .ct-minting > div {
    flex: 0 0 50%;
  }

  .ct-ciervo-lab > .ct-banner-container {
    display: flex;
  }
}

.ct-ciervo-lab .ct-call-to-action > .ct-main-banner > img {
  width: 100%;
}

.ct-ciervo-lab .ct-call-to-action > .ct-main-banner > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 20px;
}

@media screen and (min-width: 300px) {
  .ct-ciervo-lab .ct-call-to-action > .ct-main-banner > img {
    width: 50%;
  }

  .ct-ciervo-lab .ct-call-to-action > .ct-main-banner > div {
    width: 50%;
  }
}

.ct-ciervo-lab .ct-call-to-action > .ct-main-banner > div > h4 {
  font-size: 24px;
}

.ct-ciervo-lab .ct-call-to-action > .ct-main-banner > div > small {
  font-size: 12px;
  color: white;
}

.ct-ciervo-lab .ct-call-to-action > .ct-main-banner > div > small:nth-child(3) {
  margin-bottom: 10px;
}

.ct-ciervo-lab .ct-call-to-action > .ct-main-banner > div > small > a > span {
  color: white;
  font-size: 18px;
  margin: 0 3px;
}

.ct-ciervo-lab .ct-call-to-action > .ct-main-banner > div > strong {
  font-size: 12px;
  margin-top: 15px;
}

.ct-ciervo-lab .ct-about-the-project {
  display: flex;
  flex-direction: column;
  color: black;
  background: white;
  align-items: center;
  max-width: 700px;
  width: 90%;
  padding: 20px;
  box-shadow: 0 10px 20px rgba(255, 255, 255, 0.19),
    0 6px 6px rgba(255, 255, 255, 0.23);
  margin-top: -50px;
  z-index: 99;
}

.ct-ciervo-lab .ct-about-the-project h4 {
  font-family: "Permanent Marker", cursive;
  font-size: 30px;
  color: black;
  letter-spacing: 2px;
  margin-bottom: 30px;
}

.ct-ciervo-lab .ct-about-the-project p {
  text-transform: uppercase;
  font-size: 12px;
  max-width: 700px;
  text-align: justify;
  margin-bottom: 40px;
}

.ct-ciervo-lab .ct-roadmap {
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
  margin-top: 50px;
}

.ct-ciervo-lab .ct-roadmap > h4 {
  font-size: 30px;
  color: white;
  margin-bottom: 30px;
}

.ct-ciervo-lab .ct-roadmap > div {
  margin-bottom: 15px;
  display: flex;
  width: 100%;
  max-width: 600px;
}

.ct-ciervo-lab .ct-roadmap > div > h4 {
  font-size: 45px;
  width: 15%;
}

.ct-ciervo-lab .ct-roadmap > div > p {
  color: black;
  background: white;
  width: 85%;
}
