@import url("https://fonts.googleapis.com/css?family=Montserrat");

.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) perspective(600px) rotateY(20deg) rotateX(10deg);
  background: #000000;
  transform-style: preserve-3d;
  width: 120px;
  height: 150px;
  border-radius: 10px;
}
.wrapper .shape {
  background: #ffffff;
  border-radius: 10px;
  width: 120px;
  height: 150px;
  transform: translateZ(50px) scale(0.85);
  opacity: 0.8;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
}

.wrapper .shape:hover {
  background: rgb(195, 195, 195);
}

@media screen and (min-width: 500px) {
  .wrapper {
    width: 150px;
    height: 200px;
  }
  .wrapper .shape {
    width: 150px;
    height: 200px;
  }
}
