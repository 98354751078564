@font-face {
  font-family: SaucerBB;
  src: url("./SaucerBB.ttf");
}

.ct-manga-punks {
  background: black;
  width: 100%;
  overflow: hidden;
}

.ct-manga-punks > .ct-video {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.ct-manga-punks > .ct-video > video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.ct-manga-punks .ct-kamehameha.switch {
  position: relative;
  display: inline-block;
  width: 0;
  height: 20px;
  transition: 2s;
  top: 0px;
}

.ct-manga-punks .ct-kamehameha.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.ct-manga-punks .ct-kamehameha .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: -54vw;
  right: 0;
  bottom: 0;
  background-color: white;
  -webkit-transition: 1.4s;
  transition: 1.4s;
  width: 0;
  z-index: 21;
  box-shadow: 1px 1px 25px 25px rgb(108, 250, 255);
  height: 50px;
}

.ct-manga-punks .ct-kamehameha .slider:before {
  position: absolute;
  content: "";
  height: 50px;
  width: 50px;
  left: 0px;
  bottom: 0;
  background-color: white;
  -webkit-transition: 1.4s;
  transition: 1.4s;
  z-index: 1;
  box-shadow: 1px 1px 40px 25px rgba(108, 250, 255, 0.904);
}

.ct-manga-punks .ct-kamehameha input:checked .switch {
  top: -59px;
}

.ct-manga-punks .ct-kamehameha input:checked + .slider {
  background-color: white;
  width: 70vw;
  transition: 1.4s;
  box-shadow: 1px 1px 40px 25px rgb(108, 250, 255);
  height: 22px;
}

.ct-manga-punks .ct-kamehameha input:focus + .slider {
  box-shadow: 1px 1px 40px 25px rgb(108, 250, 255);
}

.ct-manga-punks .ct-kamehameha input:checked + .slider:before {
  -webkit-transform: translateX(68vw);
  -ms-transform: translateX(68vw);
  transform: translateX(68vw);
  bottom: -25px;
  height: 75px;
  width: 75px;
  left: 0px;
  mix-blend-mode: color-dodge;
}

/* Rounded sliders */
.ct-manga-punks .ct-kamehameha .slider.round {
  border-radius: 34px;
}

.ct-manga-punks .ct-kamehameha .slider.round:before {
  border-radius: 50%;
}

.ct-manga-punks .ct-info-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  flex-direction: column;
}

.ct-manga-punks .ct-info-container.ct-flexible {
  width: 100vw;
  height: 100vh;
}

.ct-manga-punks .ct-info-container.ct-active {
  display: flex;
}

.ct-manga-punks .ct-info-container .ct-kamehameha {
  position: absolute;
  left: 50vw;
  top: 60vh;
}

.ct-manga-punks .ct-shadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.52);
}

.ct-manga-punks .ct-info-container > .ct-access {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.ct-manga-punks .ct-info-container > .ct-access h4 {
  font-family: SaucerBB;
  font-size: 35px;
  color: white;
}

.ct-manga-punks .ct-info-container > .ct-access h5 {
  color: white;
  margin-top: -15px;
}

.ct-manga-punks .ct-info-container > .ct-access button {
  width: 200px;
  height: 30px;
  font-size: 14px;
  margin-top: 20px;
  text-transform: uppercase;
  background: white;
  color: black;
}

@media screen and (min-width: 400px) {
  .ct-manga-punks .ct-info-container > .ct-access h4 {
    font-size: 45px;
  }

  .ct-manga-punks .ct-info-container > .ct-access h5 {
    color: white;
  }
}

@media screen and (min-width: 600px) {
  .ct-manga-punks .ct-info-container > .ct-access h4 {
    font-size: 75px;
  }

  .ct-manga-punks .ct-info-container > .ct-access h5 {
    color: white;
  }
}

.ct-manga-punks .ct-minting {
  display: flex;
  background: black;
  margin-top: 25px;
  flex-direction: column-reverse;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 19px 38px rgba(59, 255, 245, 0.418), 0 15px 12px rgba(54, 255, 225, 0.22);
  margin-bottom: 45px;
  padding-bottom: 20px;
}

.ct-manga-punks .ct-minting .ct-minting-container {
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.ct-manga-punks .ct-minting .ct-minting-container > h4 {
  font-family: SaucerBB;
  font-size: 35px;
  margin-top: 25px;
  margin-bottom: 15px;
}

.ct-manga-punks .ct-minting .ct-minting-container input {
  border-bottom: 1px solid white;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 4px;
  height: 40px;
  max-width: 150px;
  color: white;
}

.ct-manga-punks .ct-minting .ct-minting-container button {
  margin: 10px;
  color: black;
  background: white;
  text-transform: uppercase;
  font-size: 10px;
  padding: 10px;
  height: auto;
  margin-bottom: 25px;
  min-width: 150px;
}

.ct-manga-punks .ct-minting .ct-minting-container span {
  font-size: 10px;
}

.ct-manga-punks .ct-minting .ct-animation {
  width: 100%;
}

.ct-manga-punks .ct-minting .ct-animation > img {
  width: 100%;
}

.ct-manga-punks .ct-collection-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
}

.ct-manga-punks .ct-collection-info > h4 {
  font-family: SaucerBB;
  color: white;
  font-size: 35px;
  text-align: center;
}

.ct-manga-punks .ct-collection-info > h5 {
  font-family: SaucerBB;
  color: white;
  font-size: 25px;
  text-align: center;
}

.ct-manga-punks .ct-collection-info > div {
  color: white;
  margin-top: 25px;
}

.ct-manga-punks .ct-collection-info > div > h5 {
  font-size: 25px;
}

.ct-manga-punks .ct-collection-info > div.ct-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

.ct-manga-punks .ct-collection-info > div.ct-flex > div {
  min-width: 200px;
  max-width: 400px;
  width: 100%;
  background: black;
  padding: 25px;
  box-shadow: 0 19px 38px rgba(59, 255, 245, 0.418), 0 15px 12px rgba(54, 255, 225, 0.22);
}

.ct-manga-punks .ct-collection-info > div.ct-flex > div > h5 {
  font-size: 25px;
  margin-bottom: 10px;
}

.ct-manga-punks .ct-collection-info > div.ct-flex > div p {
  font-size: 12px;
  margin-bottom: 10px;
}

.ct-manga-punks .ct-collection-info > div.ct-flex > div ul,
.ct-manga-punks .ct-collection-info > div.ct-flex > div ul > li {
  list-style: none;
  text-transform: uppercase;
  font-size: 12px;
}

.ct-manga-punks .ct-collection-info > div.ct-flex > div ul > li {
  margin-bottom: 10px;
}

.ct-manga-punks .ct-collection-info > div.ct-flex > div ul > li a {
  color: white;
}

.ct-manga-punks .ct-collection-info > .ct-line {
  background: white;
  height: 1px;
  width: 100%;
  margin-top: 4px;
}

.ct-manga-punks .ct-collection-info > .ct-line:nth-child(2) {
  margin-left: -25px;
}

.ct-manga-punks .ct-collection-info > .ct-line:nth-child(3) {
  margin-left: 25px;
}

.ct-manga-punks .ct-collection-info .ct-partners {
  display: flex;
}

.ct-manga-punks .ct-collection-info .ct-partners > div {
  width: 100px;
  color: white;
  margin: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
}

.ct-manga-punks .ct-collection-info .ct-partners > div h3 {
  font-size: 14px;
}

.ct-manga-punks .ct-collection-info .ct-partners > div a {
  color: white;
  font-size: 11px;
}

.ct-manga-punks .ct-collection-info .ct-partners > div > img {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ct-manga-punks .ct-universal-key {
  width: 90%;
  max-width: 350px;
  box-shadow: 0 19px 38px rgba(59, 255, 245, 0.418), 0 15px 12px rgba(54, 255, 225, 0.22);
  padding-top: 15px;
}
